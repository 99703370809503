<template>
  <div style="background:#f7f7f7;height:100%">
    <div>
      <div class="serve_list">
        <!-- 左侧 -->
        <span class="list-left">
          <img src="../../assets/hua1.jpg" alt="" />
          <div class="title van-ellipsis">智博建筑设计集团有限公司</div>
          <div style="text-align: center;">
            <button class="serveon"><van-icon name="phone-o" /> 联 系 企 业</button>
          </div>
        </span>
        <!-- 右侧 -->
        <span class="list-right">
          <div>负责人:卢总</div>
          <div>负责人电话:18437982110</div>
          <div>材料名称:沙子</div>
          <div>材料规模:25kg</div>
          <div>需要量:2000吨</div>
          <div>执行标准代号:GB1355</div>
        </span>
      </div>
    </div>
    <van-tabbar v-model="active">
      <van-tabbar-item icon="home-o" to="/huayang/classroom"
        >知识大讲堂</van-tabbar-item
      >
      <van-tabbar-item icon="qr" to="/huayang/serve">企业信息</van-tabbar-item>
      <van-tabbar-item icon="apps-o" to="/huayang">中介超市</van-tabbar-item>
      <van-tabbar-item icon="cluster-o" to="/huayang/interaction"
        >区企互动</van-tabbar-item
      >
      <van-tabbar-item icon="phone-circle-o" to="/huayang/lianluo"
        >联系我们</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 1,
    };
  },
};
</script>
<style scoped>
.serve_list {
  position: relative;
  width: 90%;
  margin: 10px 5%;
  border-radius: 10px;
}
.serve_list div {
  /* display: inline; */
}
.serve_list span {
  display: inline-block;
}
.serve_list img {
  width: 160px;
  height: 100px;
  border-radius: 4px;
  margin: 10px 7.5%;
}
.serve_list .list-left{
  position: absolute;
  left:0px;
  top: 0px;
  height: 200px;
  width: 50%;
  background: rgb(255, 255, 255);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.serve_list .list-left .title {
font-size:14px;
margin:0px 5px;
text-align: center;
}
.serve_list .list-right {
  position: absolute;
  right:0px;
  top: 0px;
  font-size: 14px;
  height: 200px;
  width: 50%;
  color: #666666;
  background: #fff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.serve_list .list-right div {
  margin: 8px 5px;
}
.serve_list .serveon {
  border: none;
  background: #1989fa;
  color: #fff;
  padding: 5px 13px;
  border-radius: 20px;
  margin: 10px 0px;
  width: 70%;
  font-size: 14px;
}
</style>